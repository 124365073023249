import { TextField } from "@mui/material";
import React from "react";

interface Props {
  label: string;
  placeholder: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const TextBox = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  multiline = false,
  rows = 1,
  required = false,
  error = false,
  helperText = "",
}: Props) => {
  return (
    <TextField
      required={required}
      label={label}
      placeholder={placeholder}
      name={name}
      type={
        name === "password" ? "password" : name === "amount" ? "number" : "text"
      }
      autoComplete={name === "password" ? "new-password" : "off"}
      value={value}
      onChange={onChange}
      multiline={multiline}
      rows={rows}
      variant="outlined"
      fullWidth
      color="primary"
      error={error}
      helperText={helperText}
      sx={{
        fontSize: "15px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "primary.main",
          },
        },
      }}
    />
  );
};

export default TextBox;
