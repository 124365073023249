import { useEffect, useState } from "react";
import Page from "../Components/Page";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Paper,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import axios from "axios";
import Loading from "../Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMap,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";
import Donate from "./Donate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "background.paper",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface PictureDetailInterface {
  url: string;
  public_id: string;
  format: string;
}

interface APIResponseInterface {
  pictureDetails: PictureDetailInterface;
  _id: string;
  name: string;
  address: string;
  addressLink: string;
  description: string;
  totalRaised: number;
}

const Impact = () => {
  const [data, setData] = useState<APIResponseInterface[]>([]);

  const [totalPages, setTotalPages] = useState<number>(1);
  const pageSize = 6;
  const [page, setPage] = useState<number>(1);

  const [loading, setLoading] = useState(true);

  const [donateOpen, setDonateOpen] = useState(false);
  const [selectedMasjidID, setSelectedMasjidID] = useState("");

  const fetchMasjids = async () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/masjid/?pageSize=${pageSize}&page=${page}`,
    };

    await axios
      .request(config)
      .then((response) => {
        setData(response.data.masjids);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  useEffect(() => {
    fetchMasjids();
  }, []);

  useEffect(() => {
    fetchMasjids();
  }, [page]);

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "50px",
          paddingY: "50px",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "260px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Loading />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              color={"primary"}
              fontWeight={"bold"}
            >
              Impact
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {data.map((data, index) => (
                <Grid item xs={2} sm={4} md={4} key={data._id}>
                  <Item>
                    <Card elevation={0} sx={{}}>
                      <CardMedia
                        component="img"
                        alt="masjid"
                        height="550"
                        image={data.pictureDetails.url}
                        onError={(
                          e: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                          const target = e.target as HTMLImageElement;
                          target.src = "https://placehold.co/550x330";
                        }}
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            fontSize={"1rem"}
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              color: "text.primary",
                            }}
                          />
                          <Typography
                            variant="body2"
                            component="p"
                            sx={{
                              cursor: "pointer",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "100%",
                            }}
                            onClick={() => {
                              window.open(data.addressLink, "_blank");
                            }}
                            textAlign={"start"}
                          >
                            {data.addressLink}
                          </Typography>
                        </Box>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.name}
                        </Typography>
                        <HtmlTooltip
                          title={
                            <>
                              <Typography color="inherit">
                                Masjid Description
                              </Typography>

                              {data.description}
                            </>
                          }
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign={"start"}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "100%",
                            }}
                          >
                            {data.description}
                          </Typography>
                        </HtmlTooltip>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          textAlign={"start"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "100%",
                          }}
                        >
                          <b>Donation Goal: </b> USD {data.totalRaised}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          sx={{
                            height: "40px",
                          }}
                          onClick={() => {
                            setSelectedMasjidID(data._id);
                            setDonateOpen(true);
                          }}
                        >
                          Donate Now
                        </Button>
                      </CardActions>
                    </Card>
                  </Item>
                </Grid>
              ))}
            </Grid>
            <Pagination
              sx={{
                mb: "20px",
              }}
              count={totalPages}
              color="primary"
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        )}
      </Box>
      <Donate
        open={donateOpen}
        handleClose={() => setDonateOpen(false)}
        masjid_id={selectedMasjidID}
      />
    </Page>
  );
};

export default Impact;
