import { Box } from "@mui/material";
import React from "react";

interface PageProps {
  children: React.ReactNode;
}

const Page = ({ children }: PageProps) => {
  return (
    <Box
      style={{
        width: "100%",
        paddingTop: "64px",
        overflowY: "auto",
        height: "100vh",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "primary.main",
          outline: "1px solid slategrey",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Page;
