import { Box, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../Components/Page";

const AboutUs = () => {
  const [selectedHeading, setSelectedHeading] = useState(
    "What is an Endowment"
  );

  const data = [
    {
      enabled: true,
      heading: "What is an Endowment",
      data: "An endowment is a pot of donated money that is invested to create a reliable stream of annual income for a charitable organization.\n\nEndowments are used by colleges, schools, churches, synagogues, and nonprofits because they ensure stable, annual returns that can be used to fund operational expenses.",
    },
    {
      enabled: true,
      heading: "History",
      data: "We know that many Islamic organizations in the United States do not have endowments and are financially unsustainable. Started in February 2024, The Islamic Endowment Foundation establishes grows, and manages individualized endowment funds for Masajid and other Islamic organizations across the United States. With our nonprofit status and endowment and investment knowledge, we strive to foster financial sustainability for all Islamic organizations in the United States through sharia-compliant endowments, at no cost to your Islamic organization.",
    },
    {
      enabled: true,
      heading: "Our Team",
      data: "The founder of The Islamic Endowment Foundation, Abduelwahab Hussein, earned a B.S. in Mathematics from William & Mary. He is passionate about uplifting the financial condition of the Ummah in the United States and is active in the New York City and Northern Virginia muslim communities. His goal is to ensure Islamic organizations are financially sustainable for generations to come.\n \nOur Chief Finance Advisor, Motasem Benothman, earned a B.S. in Electrical Engineering and MBA from UCLA. He is the founder of Portola Springs, an investment fund, and is also the Chairman of New Horizon Irvine Foundation, a trust that manages a multimillion dollar endowment for an Islamic school. He also holds extensive knowledge in finance and endowment investment.\n \nOur Chief Legal Advisor & Compliance Officer, Jener Sakiri, earned a B.A. in Political Science and J.D. from UCLA. He previously served as the Head of Legal for Niftify, and is now an associate attorney at Zuber Lawler, with a focus on finance and business law.\n \nOur Community Engagement Advisor, Yousof Omeish, earned a B.A. in History from Yale University. He worked as a deputy campaign manager for Muad Hrezi for U.S. Congress and is curently a special assistant to Senator Michael Bennet. He is passionate about muslim engagement in politics and serves as a consultant on matters of community and social engagment with Masajid and other Islamic organizations.",
    },
    {
      enabled: false,
      heading: "Impact",
      data: `No data found`,
    },
    {
      enabled: false,
      heading: "FAQs",
      data: `No data found`,
    },
  ];

  const handleHeadingClick = (heading: string) => {
    setSelectedHeading(heading);
  };

  useEffect(() => {
    if (window.location.pathname === "/our_team") {
      setSelectedHeading("Our Team");
      window.location.hash = "Our Team";
    }
  }, []);

  return (
    <Page>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          paddingX: "80px",
          paddingY: "50px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "260px",
        }}
      >
        <Box
          sx={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            color={"primary"}
            fontWeight={"bold"}
          >
            About Us
          </Typography>
          <Box sx={{ height: "20px" }} />

          {data.map((item) => {
            if (item.enabled) {
              return (
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  id={item.heading}
                >
                  <Typography id="Introduction" variant="h4" gutterBottom>
                    {item.heading}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    whiteSpace={"pre-wrap"}
                  >
                    {item.data}
                  </Typography>
                </section>
              );
            }
          })}
        </Box>
        <Box
          sx={{
            position: "fixed",
            right: "5%",
            top: "15%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          {data
            .filter((data) => data.enabled === true)
            .map((data) => (
              <ListItem
                className="nav-link"
                component="a"
                href={`#${data.heading}`}
                key={data.heading}
                onClick={() => handleHeadingClick(data.heading)}
                style={{
                  textDecoration: "none",
                }}
                sx={{
                  color: "#000",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={
                    data.heading === selectedHeading ? "bold" : "normal"
                  }
                >
                  {data.heading}
                </Typography>
              </ListItem>
            ))}
        </Box>
      </Box>
    </Page>
  );
};

export default AboutUs;
