import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../Components/Loading";
import { ErrorInterface } from "../Utils/ErrorInterface";

interface PictureDetailInterface {
  url: string;
  public_id: string;
  format: string;
}

interface APIResponseInterface {
  pictureDetails: PictureDetailInterface;
  _id: string;
  name: string;
  address: string;
  addressLink: string;
  description: string;
  totalRaised: number;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  masjid_id: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "background.paper",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Donate = ({ open, handleClose, masjid_id }: Props) => {
  const amounts = [10, 25, 50, 100, 250];
  const [masjid, setMasjid] = useState<APIResponseInterface>();
  const [donationAmount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<APIResponseInterface[]>([]);
  const [error, setError] = useState<ErrorInterface>({
    isError: false,
    message: "",
    statusCode: 0,
  });

  const [fieldError, setFieldError] = useState({
    field: "",
    message: "",
  });

  const [donateLoading, setDonateLoading] = useState(false);

  const checkFieldError = () => {
    if (donationAmount < 1) {
      setFieldError({
        field: "donationAmount",
        message: "Please enter a valid donation amount",
      });
      return true;
    }
    return false;
  };

  const fetchMasjidsForList = async () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/masjid/`,
    };

    await axios
      .request(config)
      .then((response) => {
        setData(response.data.masjids);
        setMasjid(response.data.masjids.find((m: any) => m._id === masjid_id));
        setLoading(false);
      })
      .catch((error) => {});
  };

  const handleSubmit = () => {
    setDonateLoading(true);
    setError({
      isError: false,
      message: "",
      statusCode: 0,
    });
    setFieldError({
      field: "",
      message: "",
    });

    if (checkFieldError()) {
      setDonateLoading(false);
      return;
    }

    const masjidId = masjid?._id;
    const url = `${process.env.REACT_APP_API_URL}/stripe/create-checkout-session/${masjidId}`;

    if (donationAmount === 0) {
      setDonateLoading(false);
      setError({
        isError: true,
        message: "Please enter a valid donation amount",
        statusCode: 400,
      });
      return;
    }
    if (masjidId === undefined) {
      setDonateLoading(false);
      setError({
        isError: true,
        message: "Please select a masjid",
        statusCode: 400,
      });
      return;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: donationAmount,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        setDonateLoading(false);
        window.location = url;
      })
      .catch((e) => {
        setDonateLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      fetchMasjidsForList();
      setMasjid(data.find((m) => m._id === masjid_id) as APIResponseInterface);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.default",

          borderRadius: 2,
          gap: "25px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <Typography
                variant="h6"
                fontWeight={300}
                component="h2"
                sx={{
                  width: "100%",
                }}
              >
                Select Masjid
              </Typography>

              <FormControl
                fullWidth
                required
                sx={{
                  fontSize: "15px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              >
                <InputLabel>Masjid</InputLabel>
                <Select
                  value={masjid?.name}
                  label={"Masjid"}
                  onChange={(event: SelectChangeEvent) => {
                    const selectedMasjid = data.find(
                      (m) => m.name === event.target.value
                    );
                    setMasjid(selectedMasjid);
                  }}
                >
                  {data
                    .map((m) => m.name)
                    .map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                }}
                fontWeight={300}
                component="h2"
                color="text.primary"
                textAlign={"start"}
              >
                Donation Amount
              </Typography>
              <TextField
                label={"Amount"}
                required
                placeholder={"Amount"}
                name={"donationAmount"}
                type={"number"}
                value={donationAmount.toString()}
                onChange={(e) => setAmount(parseInt(e.target.value))}
                variant="outlined"
                fullWidth
                color="primary"
                error={fieldError.field === "donationAmount"}
                helperText={
                  fieldError.field === "donationAmount"
                    ? fieldError.message
                    : ""
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "primary.main",
                    },
                  },
      
                  "& .MuiInputBase-root": {
                    fontWeight: 500,
                    fontSize: "20px",
                    height: "80px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        variant="h6"
                        fontWeight={300}
                        color={"primary.main"}
                      >
                        USD |
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />

              <Box>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {amounts.map((amount, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Item
                        sx={{
                          backgroundColor:
                            amount === donationAmount ? "primary.main" : "",
                          color: amount === donationAmount ? "white" : "",
                        }}
                        onClick={() => {
                          setAmount(amount);
                        }}
                      >
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="h6" fontWeight={300}>
                            USD
                          </Typography>
                          <Typography variant="h4">
                            {amount === 0 ? "Custom" : `${amount}`}
                          </Typography>
                        </Box>
                      </Item>
                    </Grid>
                  ))}
                  <Grid item xs={2} sm={4} md={4}>
                    <Item
                      sx={{
                        backgroundColor: amounts.includes(donationAmount)
                          ? ""
                          : "primary.main",
                        color: amounts.includes(donationAmount) ? "" : "white",
                      }}
                      onClick={() => {
                        setAmount(0);
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="h5">Custom</Typography>
                        <Typography variant="h5">Amount</Typography>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              <Button
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                disabled={donateLoading}
                color="primary"
                sx={{
                  height: "50px",
                  fontWeight: 400,
                }}
              >
                {donateLoading ? <Loading /> : "Donate"}
              </Button>
              {error.isError && (
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: "#ef5350",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle2" color={"secondary.main"}>
                    {error.message}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Donate;
