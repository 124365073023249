import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextBox from "../Components/TextBox";
import Page from "../Components/Page";
import axios from "axios";
import { ErrorInterface } from "../Utils/ErrorInterface";
import Loading from "../Components/Loading";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState<ErrorInterface>({
    isError: false,
    message: "",
    statusCode: 0,
  });

  const [fieldError, setFieldError] = useState({
    field: "",
    message: "",
  });

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const checkFieldError = () => {
    if (formValues.first_name === "") {
      setFieldError({
        field: "first_name",
        message: "First name is required",
      });
      return true;
    }
    if (formValues.email === "") {
      setFieldError({
        field: "email",
        message: "Email is required",
      });
      return true;
    }
    if (formValues.phone_number === "") {
      setFieldError({
        field: "phone_number",
        message: "Phone number is required",
      });
      return true;
    }
    if (formValues.message === "") {
      setFieldError({
        field: "message",
        message: "Message is required",
      });
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError({
      isError: false,
      message: "",
      statusCode: 0,
    });

    setFieldError({
      field: "",
      message: "",
    });

    if (checkFieldError()) {
      setLoading(false);
      return;
    }

    let data = JSON.stringify({
      name: formValues.first_name + " " + formValues.last_name,
      email: formValues.email,
      phoneNumber: formValues.phone_number,
      message: formValues.message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/contact/contactEmail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setSuccessOpen(true);
        setFormValues({
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          message: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        setError({
          isError: true,
          message: error.response.data,
          statusCode: error.response.status,
        });
      });
  };

  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              minHeight: "92vh",
              paddingX: "80px",
              paddingY: "50px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography variant="h2" color={"text.secondary"} fontWeight={700}>
              Contact Us
            </Typography>
            <Typography
              variant="body1"
              fontSize={16}
              color={"text.secondary"}
              fontWeight={400}
              textAlign={"center"}
              style={{ maxWidth: "80%" }}
            >
              Need to get in touch? Simply fill out the form below or write to
              us at{" "}
              <a href="https://www.gmail.com" target="_blank">
                inquiries@islamicendowmentfoundation.com
              </a>
            </Typography>
            <Box
              sx={{
                height: "20px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: "35px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <TextBox
                  required
                  label="First Name"
                  placeholder="John"
                  name="first_name"
                  value={formValues.first_name}
                  onChange={(e) =>
                    setFormValues({ ...formValues, first_name: e.target.value })
                  }
                  error={fieldError.field === "first_name"}
                  helperText={
                    fieldError.field === "first_name" ? fieldError.message : ""
                  }
                />
                <TextBox
                  label="Last Name"
                  placeholder="Doe"
                  name="last_name"
                  value={formValues.last_name}
                  onChange={(e) =>
                    setFormValues({ ...formValues, last_name: e.target.value })
                  }
                />
              </Box>
              <TextBox
                required
                label="Email"
                placeholder="example@gmail.com"
                name="email"
                value={formValues.email}
                onChange={onChangeValue}
                error={fieldError.field === "email"}
                helperText={
                  fieldError.field === "email" ? fieldError.message : ""
                }
              />

              <TextBox
                required
                label="Phone Number"
      
                placeholder="+1 (123) 456-7890"
                name="phone_number"
                value={formValues.phone_number}
                onChange={onChangeValue}
                error={fieldError.field === "phone_number"}
                helperText={
                  fieldError.field === "phone_number" ? fieldError.message : ""
                }
              />
              <TextBox
                required
                label="Message"
                placeholder="Type your message here"
                name="message"
                value={formValues.message}
                onChange={onChangeValue}
                multiline
                rows={4}
                error={fieldError.field === "message"}
                helperText={
                  fieldError.field === "message" ? fieldError.message : ""
                }
              />
              {error.isError && (
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: "#ef5350",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
 
                  }}
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle2" color={"secondary.main"}>
                    {error.message}
                  </Typography>
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
                sx={{
                  height: "60px",
                  fontSize: "15px",
                }}
                onClick={handleSubmit}
              >
                {loading ? <Loading /> : "Send Message"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={successOpen}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSuccessOpen(false);
          }}
          severity="success"
          variant="standard"
          sx={{ width: "100%" }}
        >
          Message sent successfully!
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default ContactUs;
